import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBanner from "../components/common/headerBanner/headerBanner"
import * as styles from "./404.module.scss"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <HeaderBanner title="Page Not Found" />
    <div className={styles.pageContainer}>
      <div className={styles.pageText}>
        <h3>Sorry, we could not find that page.</h3>
        <p>
          We apologize for the inconvenience, the page you are looking for no
          longer exists. Please check your URL, or you can return to the{" "}
          <Link to="/">homepage</Link>.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
